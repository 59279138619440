.nav-item {
    font-size: 15px;
    padding: 0 10px;
    text-decoration: none;
    list-style: none;
    color: var(--nav-text-color);
    font-family: 'Campton', sans-serif;
    font-weight: bold;
    display: flex;
    text-align: center;
}

/* "Hide" checkbox -- moves it off screen*/
#menu-btn {
    position: absolute;
    top: -100%;
    left: -100%;
}

/* Hide hamburger for bigger screens */
.menu-icon {
    visibility: hidden;
}

.menu {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.menu > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

/* Set width for mobile/smaller screen size. */
/* 992 px equals bootstrap lg format (used in the header), so max width is 1 px smaller*/
@media screen and (max-width: 991px) {
    header {
        display: grid;
        grid-template-areas:
            'title   title   hamburger'
            'nav     nav     nav';
    }

    .menu > ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .menu-btn {
        display: none;
    }

    .menu-icon {
        grid-area: hamburger;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        position: relative;
        user-select: none;
        visibility: visible;
    }

    .nav-icon {
        background-color: var(--black);
        display: block;
        height: 2px;
        width: 18px;
        position: relative;
    }

    .nav-icon:before {
        top: 5px;
    }

    .nav-icon:after {
        top: -5px;
    }

    .nav-icon:before,
    .nav-icon:after {
        background: var(--black);
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        transition: all 0.2s ease-out;
    }

    .menu {
        position: absolute;
        top: 5.4rem;
        z-index: 99;
        width: 100%;
        box-shadow: 0 10px 10px -5px var(--box-shadow);
        grid-area: nav;
        max-width: unset;
        max-height: 0;
        transition: max-height 0.2s ease-out;
        overflow: hidden;
        margin: 0;
        padding: 0;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
    }

    .nav-item {
        padding: 0 0 10px 0;
        background-color: var(--white);
        width: 100%;
        justify-content: center;
    }

    .menu-btn:checked ~ .menu {
        max-height: 1000px;
    }

    .menu-btn:checked ~ .menu-icon .nav-icon {
        background: transparent;
    }

    .menu-btn:checked ~ .menu-icon .nav-icon:before {
        transform: rotate(-45deg);
    }

    .menu-btn:checked ~ .menu-icon .nav-icon:after {
        transform: rotate(45deg);
    }

    .menu-btn:checked ~ .menu-icon .nav-icon:before,
    .menu-btn:checked ~ .menu-icon .nav-icon:after {
        top: 0;
    }
}
