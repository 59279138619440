.dropdownAssessmentTool::-webkit-scrollbar,
.dropdown::-webkit-scrollbar {
    border-radius: 20px;
    width: 15px;
}

.dropdownAssessmentTool::-webkit-scrollbar-track,
.dropdown::-webkit-scrollbar-track {
    border-radius: 20px;
}

.dropdownAssessmentTool::-webkit-scrollbar-thumb,
.dropdown::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 99px;
    background-color: #aaaaaa;
}
