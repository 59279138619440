@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::before,
::after {
    box-sizing: border-box;
}

:root {
    --white: #ffffff;
    --transparent-white: #ffffff00;
    --cloud: #f8f9faad;
    --cream-white: #f4f4f4;
    --black: #333;
    --dark-black: #101823;
    --snow: #f4f6fa;
    --primary-blue-hover: #224183;
    --primary-blue: #2241a1;
    --secondary-blue: #00a3bd;
    --custom-blue: #1056b2;
    --primary-purple: #873caa;
    --dark-purple: #7110ab;
    --box-shadow: #03000030;
    --light-grey: #dbdde0;
    --yellow: #eeb340;
    --light-yellow: #f3e9d7;
    --light-blue: #cce7ed;
    --green: #02b59b;
    --light-green: #d0f2ed;
    --red: #ae1313;
    --light-red: #eecaca;
    --alert-green: #187d53;
    --alert-red: #d0342c;
    --button-hover: #f4f5fa;
    --nav-text-color: #212429;
    --skillify-color: #8e85fd;
}

@font-face {
    font-family: Campton-Semibold;
    src: url('../fonts/CamptonSemiBold.otf') format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: Campton;
    src: url('../fonts/CamptonThin.otf') format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: Campton;
    src: url('../fonts/CamptonExtraLight.otf') format('opentype');
    font-weight: 200;
}

@font-face {
    font-family: Campton;
    src: url('../fonts/CamptonLight.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: Campton;
    src: url('../fonts/CamptonBook.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: Campton;
    src: url('../fonts/CamptonMedium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: Campton;
    src: url('../fonts/CamptonSemiBold.otf') format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: Campton;
    src: url('../fonts/CamptonBold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: Campton;
    src: url('../fonts/CamptonExtraBold.otf') format('opentype');
    font-weight: 800;
}

@font-face {
    font-family: Campton;
    src: url('../fonts/CamptonBlack.otf') format('opentype');
    font-weight: 900;
}

body {
    font-family: sans-serif;
}

.paragraph,
p,
span {
    font-family: 'Open Sans', sans-serif !important;
}

.title,
h1,
h2,
h3,
h4,
h5,
h6,
p.h1,
p.h2,
p.h3,
p.h4,
p.h5,
p.h6 {
    font-family: 'Red Hat Display', sans-serif !important;
}

h1 {
    font-size: 3.5rem;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 1.75rem;
}

.link,
.link:hover,
.markdown-link a,
.markdown-link a:hover {
    color: var(--primary-blue);
}

.main-blur {
    background: rgba(89, 89, 89, 0.3);
    overflow: hidden;
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.horizontal-margin {
    margin-left: 6rem;
    margin-right: 6rem;
}

.horizontal-padding {
    padding-left: 6rem;
    padding-right: 6rem;
}

.skillify-font {
    padding-top: 0.2rem;
    font-family: 'Campton', sans-serif !important;
}

/* SM */
@media (max-width: 576px) {
    .horizontal-margin {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .horizontal-padding {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
